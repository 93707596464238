/* eslint-disable max-classes-per-file */

// class AuthFailure extends Error {
//   constructor(message) {
//     super(message)
//     this.name = this.constructor.name
//   }
// }

export class AuthorisationError extends Error {
  constructor(message) {
    super(message)
    this.name = this.constructor.name
  }
}

export class NoUserInDB extends Error {
  constructor(message) {
    super(message)
    this.name = this.constructor.name
  }
}
