// *===============================================---*
// *--------- CHAT  ---------------------------------------*
// *===============================================---*
export default [
  {
    path: '/apps/chat',
    name: 'chat',
    component: () => import('@/views/apps/chat/Chat.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'chat-application',
    },
  },

]
