// Note: actions can be synchronous or asynchronous, but mutations can only synchronous.

// This is our first mutation, it takes the state and arguments of the mutations in this case it is the user.
// For mutations we are going to change the state so we can access the state, in this case we updating the user and logged in property.
export default {
  SAVE_USER_GROUP(state, userGroup) {
    state.userGroup = userGroup
    state.loggedIn = true
  },

  SAVE_USER(state, user) {
    state.user = user
  },

  USER_LOGOUT(state) {
    state.loggedIn = false
    state.userGroup = undefined
  },

}
