// Note: actions can be synchronous or asynchronous, but mutations can only synchronous.

// This is our first mutation, it takes the state and arguments of the mutations in this case it is the user.
// For mutations we are going to change the state so we can access the state, in this case we updating the user and logged in property.
export default {
  UPDATE_PROJECTS_SELECTED(state, project) {
    if (state.projectsSelected.includes(project)) {
      state.projectsSelected.splice(state.projectsSelected.indexOf(project), 1)
    } else {
      state.projectsSelected.push(project)
    }
  },

  EMPTY_PROJECTS_SELECTED(state) {
    state.projectsSelected = []
  },

  SAVE_SEARCH_QUERY(state, placeSearched) {
    state.placeSearched = placeSearched
  },

  REMOVE_SEARCH_QUERY(state) {
    state.placeSearched = undefined
  },

}

// export default {
//   UPDATE_PROJECTS_SELECTED(state, project) {
//     if (state.projectsSelected.map(i => i.label).includes(project.label)) {
//       const newList = state.projectsSelected.filter(({ label }) => !label.includes(project.label))
//       state.projectsSelected = newList
//     } else {
//       state.projectsSelected.push(project)
//     }
//     console.log('Project: ')
//     console.log(project)
//     console.log('State: ')
//     console.log(state.projectsSelected)
//   },

//   SAVE_SEARCH_QUERY(state, placeSearched) {
//     state.placeSearched = placeSearched
//   },

//   REMOVE_SEARCH_QUERY(state) {
//     state.placeSearched = undefined
//   },

// }
