export default [
  // *===============================================---*
  // *--------- ECOMMERCE  ---------------------------------------*
  // *===============================================---*
  {
    path: '/inventory/search',
    name: 'inventory',
    component: () => import('@/views/inventory/search/InventorySearch.vue'),
    meta: {
      contentRenderer: 'sidebar-left-detached-inventory',
      contentClass: 'ecommerce-application',
      pageTitle: 'Search',
      breadcrumb: [
        {
          text: 'Inventory',
          active: true,
        },
        {
          text: 'Search',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inventory/favourites',
    name: 'inventory-favourites',
    component: () => import('@/views/inventory/favourites/InventoryFavourites.vue'),
    meta: {
      pageTitle: 'Favourites',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Inventory',
          active: true,
        },
        {
          text: 'Favourites',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inventory/checkout',
    name: 'inventory-checkout',
    component: () => import('@/views/inventory/checkout/Checkout.vue'),
    meta: {
      pageTitle: 'Checkout',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Inventory',
          active: true,
        },
        {
          text: 'Checkout',
          active: true,
        },
      ],
    },
  },
  {
    path: '/inventory/map',
    name: 'inventory-map',
    component: () => import('@/views/inventory/map/InventoryMap.vue'),
    meta: {
      pageTitle: 'Map',
      // contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Inventory',
          active: true,
        },
        {
          text: 'Map',
          active: true,
        },
      ],
    },
  },
  {

    path: '/inventory/:slug',
    name: 'inventory-product-details',
    component: () => import('@/views/inventory/product-details/ProductDetails.vue'),
    meta: {
      pageTitle: 'Product Details',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'Inventory',
          active: true,
        },
        {
          text: 'Search',
          name: 'inventory',
          to: { name: 'inventory' },
        },
        {
          text: 'Product Details',
          active: true,
        },

      ],
    },
  },

]
