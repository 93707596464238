// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
// * Ensure abilityDefinerForRouting always returns a new ability because it gets use by src\libs\acl\index.js to initialize  the ability.

export const initialAbility = [
  {
    action: 'read',
    subject: 'Auth',
  },
]

export const adminAbility = [{
  action: 'manage',
  subject: 'all',
}]

export const builderAbility = [
  {
    action: 'read',
    subject: 'Auth', // Ability to access Ability Control page.
  },
  {
    action: 'read',
    subject: 'Permissions', // Ability to access Ability Control page.
  },
  {
    subject: 'AccountSetting',
    action: 'read',
  },
]

export const AbilityOfGroup = userGroup => {
  if (userGroup === 'Admin') {
    return adminAbility
  } if (userGroup === 'Builder') {
    return builderAbility
  }
  return initialAbility
}
