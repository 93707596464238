import Vue from 'vue'
import { Amplify, Auth, API } from 'aws-amplify'
import store from '@/store'
import { AbilityOfGroup } from '../libs/acl/ability'
import { AuthorisationError, NoUserInDB } from '../libs/errors'
import * as queries from '../libs/graphql/queries'

Amplify.configure({
  Auth: {
    region: process.env.VUE_APP_PLATFORM_REGION,
    userPoolId: process.env.VUE_APP_PLATFORM_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_PLATFORM_BACKOFFICE_WEB_CLIENT_ID,
    mandatorySignedIn: true,
  },
  API: {
    aws_appsync_graphqlEndpoint: process.env.VUE_APP_PLATFORM_GRAPH_QL_API_URL,
    aws_appsync_region: process.env.VUE_APP_PLATFORM_REGION,
    aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  },
})

export function GetUserGroup() {
  return store.getters['authentication/userGroup']
}

// Refresh Authentication state by setting userGroup of logged in user
// and sets their abilities in the state
// If the userGroup does not exist we know that the user is
// either not logged in or has refreshed the page so would need to
// fetch session and group from cognito or login OR user that is logged as no role
// Returns TRUE if successfully Authenticated
// or FALSE if failed to Authenticate
// RefreshAuthState throws an error if it cant get the user group, so if calling this function make sure you handle its error.
export async function RefreshAuthState() {
  const currentSession = await Auth.currentSession({
    // bypassCache: true, // * If we update the user attributes and we need updated user attributes then use bypass
  }).catch(() => false)
  if (!currentSession) return false

  const groups = currentSession.idToken.payload['cognito:groups']
  if (!groups) {
    await Auth.signOut()
    throw new AuthorisationError('You have not been assigned an appropriate role. Signing out...')
  }

  const userData = API.graphql({ query: queries.getCurrentUser }).catch(async () => {
    await Auth.signOut()
    throw new NoUserInDB('Your account information is not saved with us. Please contact support.')
  })

  // ToDo: This returns the first user group that comes back from cognito. Make sure to update when we have a group hierarchy.
  const group = groups[0]
  const user = (await userData).data.getCurrentUser

  // Logged in but no state
  Vue.prototype.$ability.update(AbilityOfGroup(group)) // probably don't need this because if user is in state, then ability should also be in Vue instance.
  await store.dispatch('authentication/saveUserGroup', group) // Side effect - also marks as loggedIn
  await store.dispatch('authentication/saveUser', user)
  return true
}

export async function IsLoggedIn() {
  return store.getters['authentication/loggedIn']
}
