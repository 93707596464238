import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import inventoryStoreModule from '@/views/inventory/inventoryStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import authentication from './authentication'
import search from './search'
import chat from './chat'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    authentication,
    search,
    inventory: inventoryStoreModule,
    chat,
  },
  strict: process.env.DEV,
})
