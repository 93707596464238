import { API } from 'aws-amplify'
import * as queries from '../../libs/graphql/queries'

export default {
  async createUserConversation(ctx, payload) {
    return new Promise((resolve, reject) => {
      API.graphql({ query: queries.createUserConversations, variables: { conversationId: payload.conversationId, userId: payload.userId } })
        .then((result => resolve(result)))
        .catch(error => reject(error))
    })
  },

  async refreshUserConversations() {
    return new Promise((resolve, reject) => {
      API.graphql({ query: queries.getCurrentUserConversations })
        .then((result => resolve(result)))
        .catch(error => reject(error))
    })
  },

  async getAllUsers() {
    return new Promise((resolve, reject) => {
      API.graphql({ query: queries.allUser })
        .then((result => resolve(result)))
        .catch(error => reject(error))
    })
  },

  async sendMessageToUser(ctx, payload) {
    return new Promise((resolve, reject) => {
      API.graphql({ query: queries.sendMessage, variables: { content: payload.content, conversationId: payload.conversationId, sender: payload.sender } })
        .then((result => resolve(result)))
        .catch(error => reject(error))
    })
  },

  async getMessages(ctx, conversationId) {
    return new Promise((resolve, reject) => {
      API.graphql({ query: queries.getMessages, variables: { conversationId } })
        .then((result => resolve(result.data.allMessageConnection.messages)))
        .catch(error => reject(error))
    })
  },

  // async newIncomingMessages({ commit }, newMessage) {
  //   commit('NEW_INCOMING_MESSAGE', newMessage)
  // },

  async saveUserSubscriptions({ commit }, conversation) {
    await commit('SAVE_USER_SUBSCRIPTIONS', { conversationId: conversation.conversationId, subscription: conversation.subscription })
  },
}
