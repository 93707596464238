import gql from 'graphql-tag'

export const getCurrentUser = gql`query GetCurrentUser{
    getCurrentUser {
    cognitoID
    createdAt
    email
    firstName
    id
    lastName
    tenantID
    imageUrl
    }
  }`

export const getProduct = gql`query GetItem($id: ID!){
  getProduct(id: $id) {
    id
    name
    salesforceID
    version
    titleExpected
    titled
    street
    stage
    city
    postcode
    state
    street
    media {
      name
      originUrl
      type
    }
    project {
      media {
        id
        name
        salesforceID
        originUrl
        type
      }
      averagePrice
      description
      heroURL
      id
      latitude
      logoURL
      longitude
      name
      productCount
      vendorName
      version
    }
    depth
    frontage
    lotPlanUrl
    price
    size
    titled
  }
}`

export const allUser = gql`query allUser{
    allUser {
    cognitoID
    email
    firstName
    id
    lastName
  }
}`

export const createConversation = gql`mutation createConversation($name: String!) {
  createConversation(name: $name) {
    id,
    name,
    createdAt
  }
}`

export const createUserConversations = gql` mutation createUserConversations($conversationId: ID!, $userId: ID!) {
  createUserConversations(conversationId: $conversationId, userId: $userId) {
    __typename
    userId
    conversationId
    conversation {
      __typename
      id
      name
    }
    associated {
      __typename
      userId
    }
  }
}`

export const getCurrentUserConversations = gql` query GetCurrentUser($after: String, $first: Int) {
  getCurrentUser {
    firstName
    lastName
    id
    email
    cognitoID
    conversations(after: $after, first: $first) {
      userConversations {
        conversationId
        userId
        lastMessage {
          content
          sender
        }
        associated {
          userId
          conversationId
        }
        conversation {
          createdAt
          id
          name
        }
      }
      nextToken
    }
  }
}`

export const getMessages = gql` query GetMessages($conversationId: ID!, $after: String, $first: Int) {
    allMessageConnection(conversationId: $conversationId, after: $after, first: $first) {
    messages {
      content
      conversationId
      createdAt
      id
      isSent
      sender
    }
    nextToken
  }
}`

export const sendMessage = gql` mutation SendMessage($content: String!, $conversationId: ID!, $sender: String!) {
    createMessage(content: $content, conversationId: $conversationId, sender: $sender) {
    content
    conversationId
    createdAt
    id
    isSent
    sender
  }
}`

export const subscribeToNewMessages = gql` subscription SubscribeToNewMessage($conversationId: ID!) {
  subscribeToNewMessage(conversationId: $conversationId) {
    __typename
    conversationId
    createdAt
    id
    sender
    content
    isSent
  }
}`

export const subscribeToNewUserConversations = gql`
subscription subscribeToNewUCs($userId: ID!) {
  subscribeToNewUCs(userId: $userId) {
    __typename
    userId
    conversationId
    conversation {
      __typename
      id
      name
    }
    associated {
      __typename
      userId
    }
  }
}`
