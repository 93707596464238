import { canNavigate } from '@/libs/acl/routeProtection'
import getHomeRouteForGroup from '@/auth/utils'
import { GetUserGroup, IsLoggedIn, RefreshAuthState } from '@/auth/amplify'

export default async (to, from, next) => {
  const isLoggedIn = !(await IsLoggedIn()) ? await RefreshAuthState().catch(() => false) : true

  if (!(await canNavigate(to))) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next(getHomeRouteForGroup(GetUserGroup()))
  }

  return next()
}
